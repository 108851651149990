<template>
	<div>
		<v-row class="pt-3">
			<v-col cols="12"
				><h2 class="text-h4">Información de la Laguna Maule</h2></v-col
			>

			<v-col cols="4">
				<v-card>
					<v-card-title primary-title>
						<v-checkbox v-model="acm_regulation_set"></v-checkbox>
						Consigna Junta de Vigilancia Río Maule
					</v-card-title>

					<template v-if="acmInfo.acm_regulation">
						<v-card-text v-if="acm_regulation_set">
							<v-text-field
								v-model="acmInfo.acm_regulation.value"
								label="Valor"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.acm_regulation.origin"
								label="Fuente"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.acm_regulation.updated"
								label="Fecha actualización"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.acm_regulation.url"
								label="Link"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.acm_regulation.comment"
								label="Comentario"
							></v-text-field>
						</v-card-text>

						<v-card-text v-else>
							Valor: {{ acmInfo.acm_regulation.value }}<br />
							Fuente: {{ acmInfo.acm_regulation.origin }}<br />
							Fecha actualización:
							{{ acmInfo.acm_regulation.updated }}<br />
							Link: {{ acmInfo.acm_regulation.url }}<br />
							Comentario: {{ acmInfo.acm_regulation.comment }}
						</v-card-text>
					</template>
				</v-card>
			</v-col>

			<v-col cols="4">
				<v-card>
					<v-card-title primary-title>
						<v-checkbox v-model="extraction_set"></v-checkbox>
						Aporte Laguna del Maule
					</v-card-title>

					<template v-if="acmInfo.extraction">
						<v-card-text v-if="extraction_set">
							<v-text-field
								v-model="acmInfo.extraction.value"
								label="Valor"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.extraction.origin"
								label="Fuente"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.extraction.updated"
								label="Fecha actualización"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.extraction.url"
								label="Link"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.extraction.comment"
								label="Comentario"
							></v-text-field>
						</v-card-text>

						<v-card-text v-else>
							Valor: {{ acmInfo.extraction.value }}<br />
							Fuente: {{ acmInfo.extraction.origin }}<br />
							Fecha actualización:
							{{ acmInfo.extraction.updated }}<br />
							Link: {{ acmInfo.extraction.url }}<br />
							Comentario: {{ acmInfo.extraction.comment }}
						</v-card-text>
					</template>
				</v-card>
			</v-col>



			<template v-if="acmInfo.contributions">
				<v-col
					cols="4"
					v-for="(item, index) in acmInfo.contributions"
					:key="`contributions-${index}`"
				>
					<v-card>
						<v-card-title primary-title>
							<v-checkbox
								v-model="contributions_set"
								:value="index"
							></v-checkbox>
							{{ item.title }}
						</v-card-title>

						<v-card-text v-if="contributions_set.includes(index)">
							<v-text-field
								v-model="acmInfo.contributions[index].title"
								label="Título"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.contributions[index].value"
								label="Valor"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.contributions[index].origin"
								label="Fuente"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.contributions[index].updated"
								label="Fecha actualización"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.contributions[index].url"
								label="Link"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.contributions[index].comment"
								label="Comentario"
							></v-text-field>
						</v-card-text>

						<v-card-text v-else>
							Título: {{ acmInfo.contributions[index].title
							}}<br />
							Valor: {{ acmInfo.contributions[index].value
							}}<br />
							Fuente: {{ acmInfo.contributions[index].origin
							}}<br />
							Fecha actualización:
							{{ acmInfo.contributions[index].updated }}<br />
							Link:
							{{ acmInfo.contributions[index].url }}<br />
							Comentario:
							{{ acmInfo.contributions[index].comment }}
						</v-card-text>
					</v-card>
				</v-col>
			</template>
			
			<v-col cols="4">
				<v-card>
					<v-card-title primary-title>
						<v-checkbox v-model="volume_set"></v-checkbox>
						Volumen Laguna del Maule
					</v-card-title>

					<template v-if="acmInfo.volume">
						<v-card-text v-if="volume_set">
							<v-text-field
								v-model="acmInfo.volume.value"
								label="Valor"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.volume.origin"
								label="Fuente"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.volume.updated"
								label="Fecha actualización"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.volume.url"
								label="Link"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.volume.comment"
								label="Comentario"
							></v-text-field>
						</v-card-text>

						<v-card-text v-else>
							Valor: {{ acmInfo.volume.value }}<br />
							Fuente: {{ acmInfo.volume.origin }}<br />
							Fecha actualización:
							{{ acmInfo.volume.updated }}<br />
							Link: {{ acmInfo.volume.url }}<br />
							Comentario: :
							{{ acmInfo.volume.comment }}
						</v-card-text>
					</template>
				</v-card>
			</v-col>

			<template v-if="acmInfo.pluviometry">
				<v-col
					cols="4"
					v-for="(item, index) in acmInfo.pluviometry"
					:key="`pluviometry-${index}`"
				>
					<v-card>
						<v-card-title primary-title>
							<v-checkbox
								v-model="pluviometry_set"
								:value="index"
							></v-checkbox>
							Pluviometría
						</v-card-title>

						<v-card-text v-if="pluviometry_set.includes(index)">
							<v-text-field
								v-model="acmInfo.pluviometry[index].value"
								label="Valor"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.pluviometry[index].origin"
								label="Fuente"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.pluviometry[index].updated"
								label="Fecha actualización"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.pluviometry[index].url"
								label="Link"
							></v-text-field>
							<v-text-field
								v-model="acmInfo.pluviometry[index].comment"
								label="Comentario"
							></v-text-field>
						</v-card-text>

						<v-card-text v-else>
							Agua caída día de ayer: {{ acmInfo.pluviometry[index].value }}<br />
							Sector: {{ acmInfo.pluviometry[index].origin
							}}<br />
							Fecha actualización:
							{{ acmInfo.pluviometry[index].updated }}<br />
							Origen de datos: {{ acmInfo.pluviometry[index].url }}<br />
							Acumulado de Abril 2024 al día de hoy: {{ acmInfo.pluviometry[index].comment }}
						</v-card-text>
					</v-card>
				</v-col>
			</template>
		</v-row>

		<v-row>
			<v-col>
				<v-btn @click="save()" color="primary">Guardar</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'acm-info',
		data() {
			return {
				acm_regulation_set: false,
				acm_regulation: null,
				extraction_set: false,
				extraction: null,
				contributions_set: [],
				contributions: null,
				volume_set: false,
				volume: null,
				pluviometry_set: [],
				pluviometry: null,
			};
		},

		watch: {
			acm_regulation_set(newValue) {
				this.setFields('acm_regulation', newValue);
			},
			extraction_set(newValue) {
				this.setFields('extraction', newValue);
			},
			contributions_set(newValue) {
				newValue.length
					? this.setFields('contributions', newValue)
					: (this.contributions = null);
			},
			volume_set(newValue) {
				this.setFields('volume', newValue);
			},
			pluviometry_set(newValue) {
				newValue.length
					? this.setFields('pluviometry', newValue)
					: (this.pluviometry = null);
			},
		},

		computed: {
			...mapState({
				acmInfo: state => state.acmControl.acmInfo,
			}),
			toSend() {
				let send = {};
				if (this.acm_regulation)
					send.acm_regulation = this.acm_regulation;
				if (this.extraction) send.extraction = this.extraction;
				if (this.contributions) send.contributions = this.contributions;
				if (this.volume) send.volume = this.volume;
				if (this.pluviometry) send.pluviometry = this.pluviometry;

				return send;
			},
		},

		mounted() {
			this.readAcmInfo();
		},

		methods: {
			...mapActions('acmControl', ['readAcmInfo', 'saveAcmInfo']),

			setFields(type, data) {
				if (data) {
					this[type] = this.acmInfo[type];
					this[type].updated = this.acmInfo[type].updated
						? this.$utils.moment().format('DD/MM/YYYY')
						: '';
						
				} else {
					this[type] = null;
				}
			},

			save() {
				if (this.toSend) {
					this.saveAcmInfo(this.toSend).then(() => {
						this.acm_regulation_set = false;
						this.acm_regulation = null;
						this.extraction_set = false;
						this.extraction = null;
						this.contributions_set = [];
						this.contributions = null;
						this.volume_set = false;
						this.volume = null;
						this.pluviometry_set = [];
						this.pluviometry = null;
					});
				}
			},
		},
	};
</script>
